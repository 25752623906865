import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';


const scrollToSection = (sectionId) => {
  setTimeout(() => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  }, 500);
};

const NavigationButton = ({ to, sectionId }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(to);
    scrollToSection(sectionId);
  };

  return (
    <button type="button" className="btn btn-primary" onClick={handleClick}>
      Saber mais <FaArrowRight size="24" color="#fff" />
    </button>
  );
};

export default NavigationButton;
