import React, { useEffect  , useRef} from 'react';
import {
  FaCity,
  FaLaptopCode,
  FaCode,
  FaProjectDiagram,
  FaUserCog,
  FaNetworkWired,
  FaArrowLeft,
  FaArrowRight,
} from 'react-icons/fa';
import Carousel from 'react-multi-carousel';

import './style.scss';

import logoVtexPartners from '../../../../img/logo_vtexPartner.png'
import logoGrupoGlatz from '../../../../img/logo_GrupoGlatz.png'
import logoGlatzon from '../../../../img/logo_glatzon.png';
import logoSenior from '../../../../img/logo_senior.png';
import logoNBWdark from '../../../../img/logoNBW-dark.png';
import logoMicrosoftPartner from '../../assets/img/logoMicrosoftPartner.png';
import logoItil from '../../assets/img/logoItil.png';
import logoAngular from '../../assets/img/ServicesLogos/logoAngular.png';
import logoJava from '../../assets/img/ServicesLogos/logoJava.png';
import logoLinx from '../../assets/img/ServicesLogos/logoLinx.png';
import logoMysql from '../../assets/img/ServicesLogos/logoMysql.png';
import logoNode from '../../assets/img/ServicesLogos/logoNode.png';
import logoOracle from '../../assets/img/ServicesLogos/logoOracle.png';
import logoPhp from '../../assets/img/ServicesLogos/logoPhp.png';
import logoPostgres from '../../assets/img/ServicesLogos/logoPostgres.png';
import logoPython from '../../assets/img/ServicesLogos/logoPython.png';
import logoSap from '../../assets/img/ServicesLogos/logoSap.png';
import logoCsharp from '../../assets/img/ServicesLogos/logoCsharp.png';
import logoReact from '../../assets/img/ServicesLogos/logoReact.png';
import logoReactNative from '../../assets/img/ServicesLogos/logoReactNative.png';
import { light } from '@mui/material/styles/createPalette';

export default function Services() {
  let numTabsAtOnce = 0;
  const windowWidth = window.innerWidth;
  let cards = [];

  const carouselResponsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 4,
      slidesToSlide: 4,
    },
    tablet: {
      breakpoint: { max: 1199, min: 850 },
      items: 3,
      slidesToSlide: 3,
    },
    mobile2: {
      breakpoint: { max: 849, min: 501 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile1: {
      breakpoint: { max: 500, min: 0 },
      items: 2,
      slidesToSlide: 2,
    },
  };

  function CustomRightArrow() {
    return false;
  }
  function CustomLeftArrow() {
    return false;
  }

  function setCardMenu(card) {
    const currentCard = card;
    if (currentCard.getElementsByClassName('card-header-tabs').length > 0) {
      const cardTabs = card
        .getElementsByClassName('card-header-tabs')[0]
        .getElementsByClassName('nav-item');
      const tabsDisplayed = [];
      const cardBodies = currentCard.getElementsByClassName('card-body');
      for (let i = 0; i < cardTabs.length; i += 1) {
        if (i <= numTabsAtOnce - 1) tabsDisplayed.push(1);
        else tabsDisplayed.push(0);

        const button = cardTabs[i].getElementsByTagName('button')[0];
        button.onclick = () => {
          for (let j = 0; j < cardTabs.length; j += 1) {
            cardTabs[j].getElementsByTagName('button')[0].classList.remove('active');
          }
          button.classList.add('active');
          for (let k = 0; k < cardBodies.length; k += 1) {
            cardBodies[k].classList.add('d-none');
          }
          currentCard
            .getElementsByClassName(`card-body-${button.id}`)[0]
            .classList.remove('d-none');
        };
      }

      for (let l = 0; l < tabsDisplayed.length; l += 1) {
        if (tabsDisplayed[l] === 1) cardTabs[l].classList.remove('d-none');
        else cardTabs[l].classList.add('d-none');
      }
      if (
        currentCard.getElementsByClassName('card-header')[0].getElementsByClassName('menu-prev')
          .length !== 0
      ) {
        currentCard
          .getElementsByClassName('card-header')[0]
          .getElementsByClassName('menu-prev')[0].firstElementChild.onclick = () => {
            const tempChild =
              currentCard.getElementsByClassName('card-header-tabs')[0].lastElementChild;
            currentCard.getElementsByClassName('card-header-tabs')[0].lastElementChild.remove();
            currentCard.getElementsByClassName('card-header-tabs')[0].prepend(tempChild);
            for (let i = 0; i < tabsDisplayed.length; i += 1) {
              if (tabsDisplayed[i] === 1) cardTabs[i].classList.remove('d-none');
              else cardTabs[i].classList.add('d-none');
            }
            let tempId = 0;
            for (let j = 0; j < cardTabs.length; j += 1) {
              if (cardTabs[j].getElementsByTagName('button')[0].classList.contains('active')) {
                cardTabs[j].getElementsByTagName('button')[0].classList.remove('active');
                if (j - 1 < 0) {
                  tempId = cardTabs[cardTabs.length - 1].getElementsByTagName('button')[0].id;
                  cardTabs[cardTabs.length - 1]
                    .getElementsByTagName('button')[0]
                    .classList.add('active');
                } else {
                  tempId = cardTabs[j - 1].getElementsByTagName('button')[0].id;
                  cardTabs[j - 1].getElementsByTagName('button')[0].classList.add('active');
                }
                break;
              }
            }
            for (let k = 0; k < cardBodies.length; k += 1) {
              cardBodies[k].classList.add('d-none');
            }
            currentCard.getElementsByClassName(`card-body-${tempId}`)[0].classList.remove('d-none');
          };
      }
      if (
        currentCard.getElementsByClassName('card-header')[0].getElementsByClassName('menu-next')
          .length !== 0
      ) {
        currentCard
          .getElementsByClassName('card-header')[0]
          .getElementsByClassName('menu-next')[0].firstElementChild.onclick = () => {
            const tempChild =
              currentCard.getElementsByClassName('card-header-tabs')[0].firstElementChild;
            currentCard.getElementsByClassName('card-header-tabs')[0].firstElementChild.remove();
            currentCard.getElementsByClassName('card-header-tabs')[0].append(tempChild);
            for (let i = 0; i < tabsDisplayed.length; i += 1) {
              if (tabsDisplayed[i] === 1) cardTabs[i].classList.remove('d-none');
              else cardTabs[i].classList.add('d-none');
            }
            let tempId = 0;
            for (let j = 0; j < cardTabs.length; j += 1) {
              if (cardTabs[j].getElementsByTagName('button')[0].classList.contains('active')) {
                cardTabs[j].getElementsByTagName('button')[0].classList.remove('active');
                if (j + 1 > cardTabs.length - 1) {
                  tempId = cardTabs[0].getElementsByTagName('button')[0].id;
                  cardTabs[0].getElementsByTagName('button')[0].classList.add('active');
                } else {
                  tempId = cardTabs[j + 1].getElementsByTagName('button')[0].id;
                  cardTabs[j + 1].getElementsByTagName('button')[0].classList.add('active');
                }
                break;
              }
            }
            for (let k = 0; k < cardBodies.length; k += 1) {
              cardBodies[k].classList.add('d-none');
            }
            currentCard.getElementsByClassName(`card-body-${tempId}`)[0].classList.remove('d-none');
          };
      }
    }
  }

  function numDisplayedCards() {
    if (windowWidth > 1200) {
      numTabsAtOnce = 6;
    } else if (windowWidth > 1050) {
      numTabsAtOnce = 5;
    } else if (windowWidth > 960) {
      numTabsAtOnce = 4;
    } else if (windowWidth > 767) {
      numTabsAtOnce = 3;
    } else if (windowWidth > 540) {
      numTabsAtOnce = 2;
    } else {
      numTabsAtOnce = 1;
    }

    setCardMenu(cards[0]);
  }

  cards = document.getElementsByClassName('card');
  useEffect(() => {
    numDisplayedCards();
  });

  window.onresize = () => {
    numDisplayedCards();
  };

  return (
    <>
      <div className="container container-title fadeInDown">
        <div className="row">
          <div className="col">
            <div className="mb-4">
              <h2 className="title-3 mb-0">NOSSAS MARCAS E COMO AJUDAMOS</h2>
            </div>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-lg-10">
            <div>
              <p className="mb-0 text-justify">
                Hub de empresas dedicado à inovação e tecnologia, estruturado em três principais frentes de atuação.
                Nosso objetivo é atender às principais demandas do mercado com excelência e inovação.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container card-container">
        <div className="card rounded fadeIn">
          <div className="card-header p-0 h-60px border-bottom-0 d-flex align-items-center justify-content-between">
            <div className="mw-50px ms-3 menu-prev">
              <FaArrowLeft size="25" color="#212529" />
            </div>
            <ul className="nav nav-tabs card-header-tabs menu-autoplay justify-content-center m-0 text-center">
              <li className="nav-item mw-175px">
                <button type="button" id="5" className="nav-link rounded-top h-60px active">

                  <img src={logoNBWdark} alt="NBW Logo" className="me-2" style={{ width: '60%', height: 'auto', maxHeight: '65px', objectFit: 'cover' }} />

                </button>
              </li>
              <li className="nav-item mw-175px">
                <button type="button" id="3" className="nav-link rounded-top h-60px">
                  <div className="d-flex flex-column align-items-center h-100">
                    <img src={logoGlatzon} alt="Logo Glatzon" className="me-2" style={{ width: '60%', height: 'auto', maxHeight: '65px', objectFit: 'cover' }} />
                    <img src={logoSenior} alt="Logo Senior canal de distribuição" className="me-2" style={{ width: '45%', height: 'auto', maxHeight: '65px', objectFit: 'cover' }} />
                  </div>
                </button>
              </li>
              <li className="nav-item mw-175px">
                <button type="button" id="1" className="nav-link rounded-top h-60px">
                  <div className="d-flex flex-column align-items-center h-100">
                    <img src={logoGrupoGlatz} alt="Logo Grupo Glatz" className="me-2" style={{ width: '60%', height: 'auto', maxHeight: '65px', objectFit: 'cover' }} />
                    <img src={logoVtexPartners} alt="Logo vtex" className="me-2" style={{ width: '20%', height: 'auto', maxHeight: '65px' }} />
                  </div>
                </button>
              </li>
            </ul>
            <div className="mw-50px me-3 menu-next">
              <FaArrowRight size="25" color="#212529" />
            </div>
          </div>
          <div className="card-body card-body-5">
            <div className="d-flex justify-content-center align-items-center mt-md-5 mb-md-3 mt-3 mb-2">
              <h2 className="title-3 text-center mb-0 d-flex align-items-center">
                <a href="https://www.nbwdigital.com.br/" className="ms-2" target="_blank" style={{ textDecoration: 'underline', color: '#212529' }}>- NBW DIGITAL</a>
              </h2>
            </div>
            <ul>
              <p className="p-0 p-md-2"> Divisão de serviços do Hub, é especializada na prestação de serviços de TI, fábrica de software, outsourcing e terceirização de mão de obra, além de consultoria em TI.</p>
              <h4>
                SERVIÇOS
              </h4>
              <p className="p-0 p-md-2">Com um compromisso sólido em atender às demandas do setor de TI, a NBW Digital se destaca pela sua expertise e excelência na entrega de serviços de alta qualidade.
                Nossa equipe de profissionais altamente qualificados está dedicada a resolver os diversos desafios do mercado, otimizando e transformando os negócios de nossos clientes com soluções inovadoras e eficientes.</p>
              <li><p className="p-0 p-md-2 ">OUTSOURCING:
                ALOCAÇÃO DE PROFISSIONAIS E ATENDIMENTO  ESPECIALIZADO</p></li>
              <li><p className="p-0 p-md-2 ">SOFTWARE HOUSE - SOLUÇÃO SOB DEMANDA</p></li>
              <li><p className="p-0 p-md-2 ">CONSULTORIA EM TI E INOVAÇÃO</p></li>

              <h4>
                NOSSAS ESPECIALIDADES
              </h4>
              <li><p className="p-0 p-md-2">GESTÃO DE PROJETOS,
                SUPORTE E SUSTENTAÇÃO ESPECIALIZADOS</p></li>
            </ul>
          </div>

          <div className="card-body card-body-3 d-none">
            <h2 className="title-3 text-center mt-md-5 mb-md-3 mt-3 mb-2">
              <a href="https://glatzon.com.br/" className="ms-2" target="_blank" style={{ textDecoration: 'underline', color: '#212529' }}>- GLATZON - CANAL SENIOR</a>
            </h2>
            <ul>
              <p className="p-0 p-md-2"> Atuando como um canal de distribuição SÊNIOR, é dedicada à oferta de uma ampla variedade de soluções de TI (como RH, ERP e outros), atendendo a diversos segmentos de mercado e nichos específicos. </p>
              <h4>
                SOLUÇÕES
              </h4>
              <p className="p-0 p-md-2">Comprometida em superar as expectativas do setor, a Glatzon se destaca pela entrega de sistemas robustos e altamente qualificados. Nossas soluções tecnológicas são projetadas para transformar e otimizar os negócios de nossos clientes, garantindo eficiência e inovação em cada projeto.</p>
              <li><p className="p-0 p-md-2 ">ERP - GESTÃO EMPRESARIAL</p></li>
              <li><p className="p-0 p-md-2 ">HCM - SOLUÇÃO PARA RH</p></li>
              <li><p className="p-0 p-md-2 ">RONDA - CONTROLE DE PONTO E ACESSO</p></li>
              <li><p className="p-0 p-md-2 ">X PLATAFORM - GED | CHATBOT | SIGN</p></li>
            </ul>
          </div>

          <div className="card-body card-body-1 d-none">
            <h2 className="title-3 text-center mt-md-5 mb-md-3 mt-3 mb-2">
              <a href="https://www.grupoglatz.com.br/" className="ms-2" target="_blank" style={{ textDecoration: 'underline', color: '#212529' }}>- GRUPO GLATZ - CANAL VTEX</a>
            </h2>
            <ul>
              <p className="p-0 p-md-2"> Atuando como parceiro VTEX, especializado em soluções para o comércio digital. Oferecendo desde implantação. sustentação e suporte de uma  gama de soluções para comércio eletrônico e digitalização de marcas</p>
              <h4>
                SOLUÇÕES PARA COMÉRCIO ELETRÔNICO
              </h4>
              <p className="p-0 p-md-2"> O Grupo Glatz, parceiro oficial da consolidada empresa VTEX, oferece diversas soluções para o comércio eletrônico, atendendo tanto empresas que já possuem e-commerce quanto aquelas que desejam digitalizar suas marcas. Nossa equipe proporciona implantação, suporte e sustentação contínuos de sistemas para o comércio eletrônico, focando na otimização e transformação digital dos negócios. Com soluções de alta qualidade, o Grupo Glatz impulsiona o sucesso e a competitividade das marcas no ambiente digital.</p>

              <div className="d-flex align-items-start">
                <img src={logoVtexPartners} alt='Logo Vtex' style={{ width: '150px', height: 'auto', marginRight: '20px' }} />

                <ul className="list-unstyled">
                  <li>
                    <p className="p-0 p-md-2">
                      PLATAFORMA DE COMÉRCIO DIGITAL
                    </p>
                  </li>
                  <li>
                    <p className="p-0 p-md-2">
                      SISTEMA DE GERENCIAMENTO DE PEDIDOS
                    </p>
                  </li>
                  <li>
                    <p className="p-0 p-md-2">
                      MARKETPLACE E GESTÃO DE SELLERS
                    </p>
                  </li>
                  <li>
                    <p className="p-0 p-md-2">
                      COMERCIO B2B & B2C
                    </p>
                  </li>
                  <li>
                    <p className="p-0 p-md-2">
                      OMNICHANNEL
                    </p>
                  </li>
                  <li>
                    <p className="p-0 p-md-2">
                      LIVE SHOPPING E OUTROS ADD-ONS
                    </p>
                  </li>
                </ul>
              </div>
            </ul>
          </div>
        </div>
      </div>

      <div className="container container-technologies pt-4 pb-3 mb-3">
        <div className="row align-items-center text-md-left">
          <div className="col-lg-7 mb-5 mb-lg-0 fadeInLeft text-center">
            <h2 className="ls-0 title-3 mb-4 text-start">TECNOLOGIAS</h2>
            <p className="text-justify">
              Assim como nas antigas maravilhas do mundo, construtores e equipes de profissionais
              utilizaram das mais diversas ferramentas para transformar material bruto em
              monumentos. Nossos profissionais e especialistas utilizam das melhores ferramentas do
              mercado para criar soluções com o máximo de qualidade e atingir o sucesso na
              transformação digital.
            </p>
            <p className="mb-3 pr-md-3 text-justify">
              Estamos a disposição para auxiliar pessoas e organizações a utilizarem também das
              melhores <strong>metodologias</strong> do mercado para aplicação tecnológica adequada.
            </p>
          </div>
          <div className="col-lg-5 text-center fadeIn">
            <div className="row tech-logos">
              <div className="col-6 col-md-6 px-0 mb-6 mb-md-0">
                <img height="60px" src={logoMicrosoftPartner} alt="MICROSOFT PARTNER SILVER" />
              </div>
              <div className="col-6 col-md-6 px-0 mb-6 mb-md-0">
                <img height="60px" src={logoItil} alt="ITIL FOUNDATION CERTIFIED" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container carousel-technologies fadeIn">
        <div className="text-center">
          <Carousel
            swipeable={false}
            draggable={false}
            responsive={carouselResponsive}
            ssr
            infinite
            autoPlay
            autoPlaySpeed={1}
            keyBoardControl
            customTransition="all 6s linear"
            transitionDuration={6000}
            customLeftArrow={<CustomLeftArrow />}
            customRightArrow={<CustomRightArrow />}
            itemClass="text-center"
          >
            <img height="80px" src={logoAngular} alt="Logo Angular" />
            <img height="80px" src={logoJava} alt="Logo Java" />
            <img height="80px" src={logoLinx} alt="Logo Linx" />
            <img height="80px" src={logoMysql} alt="Logo MYSQL" />
            <img height="80px" src={logoNode} alt="Logo Node" />
            <img height="80px" src={logoOracle} alt="Logo Oracle" />
            <img height="80px" src={logoPhp} alt="Logo PHP" />
            <img height="80px" src={logoPostgres} alt="Logo PostgreSQL" />
            <img height="80px" src={logoPython} alt="Logo Python" />
            <img height="80px" src={logoSap} alt="Logo SAP" />
            <img height="80px" src={logoCsharp} alt="Logo C#" />
            <img height="80px" src={logoReact} alt="Logo React" />
            <img height="80px" src={logoReactNative} alt="Logo React Native" />
          </Carousel>
        </div>
      </div>
    </>
  );
}
