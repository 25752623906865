import React from 'react';
import { createRoot } from 'react-dom/client';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaTimes } from 'react-icons/fa';
import { alertOptions } from './Modules/Admin/assets/scripts/global';
import App from './App';

// Simular o AlertTemplate com o toastify
const AlertTemplate = ({ message, close, ...props }) => (
  <div className={`alert alert-${props.options.type}`}>
    {message}
    <button type="button" onClick={close}>
      <FaTimes />
    </button>
  </div>
);

const showToast = (message, type) => {
  toast(message, { type });
};

// Simulação de como o alertOptions pode ser utilizado com toastify
alertOptions.show = showToast;

// Criar a raiz do aplicativo usando createRoot
const root = createRoot(document.getElementById('root'));

// Renderizar o aplicativo dentro de StrictMode para ativar verificações adicionais
root.render(
  <React.StrictMode>
      <App />
  </React.StrictMode>
);

// export default AlertTemplate;
