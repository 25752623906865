import React from 'react';
import { Link } from 'react-router-dom';
import { scrollTop } from '../../assets/scripts/global';

import {
  FaUsers,
  FaMugHot,
  FaChartLine,
  FaMedal,
  FaArrowRight,
  FaLightbulb,
  FaLayerGroup,
  FaCogs,
} from 'react-icons/fa';
import './style.scss';
import banner from '../../assets/img/banner.png';
import NavigationButton from '../Services/NavigationButton.js'; 

let animationExecuted = false;

function animateValue(obj, start, end, duration, plus) {
  const object = obj;
  let startTimestamp = null;

  const step = (timestamp) => {
    if (!startTimestamp) startTimestamp = timestamp;
    const progress = Math.min((timestamp - startTimestamp) / duration, 1);
    object.innerHTML = Math.floor(progress * (end - start) + start);

    if (plus) {
      object.innerHTML += '+';
    } else {
      object.innerHTML += '';
    }

    if (progress < 1) {
      window.requestAnimationFrame(step);
    }
  };
  window.requestAnimationFrame(step);
}

window.addEventListener(
  'scroll',
  () => {
    if (window.location.href.split('/')[3] === '') {
      if (window.scrollY > 50) {
        document.getElementById('navbar').classList.add('navbar-black');
      } else {
        document.getElementById('navbar').classList.remove('navbar-black');
      }

    }
  },
  false
);

export default function Home() {
  return (
    <>
      {/* Banner */}
      <div className="banner">
        <img src={banner} alt="Banner home page" />
      </div>

      {/* Services Cards */}
      <div className="container services-cards">
        <div className="row justify-content-center">
          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
            <div className="card mt-5 align-items-center text-center">
              <FaLightbulb size="56" color="#3e2a67" />
              <div className="card-body">
                <h5 className="card-title">ÁREAS DE ATUAÇÃO</h5>
                <p className="card-text">
                  Nossas soluções são desenhadas e construídas para melhor servir na necessidade.
                  Aliar nossa disposição com as técnicas mais inovadoras do mercado é garantia de
                  satisfação.
                </p>
                <Link to="/Services" onClick={scrollTop}>
                    <button type="button" className="btn btn-primary">
                      Saber mais <FaArrowRight size="24" color="#fff" />
                    </button>
                  </Link>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
            <div className="card mt-5 align-items-center text-center">
              <FaLayerGroup size="56" color="#3e2a67" />
              <div className="card-body">
                <h5 className="card-title">QUEM SOMOS</h5>
                <p className="card-text">
                  Sempre há espaço para melhorar aquilo que já está bom. Buscar as melhores
                  alternativas para que os resultados sejam sempre maximizados faz parte de nosso
                  objetivo.
                </p>
                <Link to="/About" onClick={scrollTop}>
                    <button type="button" className="btn btn-primary">
                      Saber mais <FaArrowRight size="24" color="#fff" />
                    </button>
                    </Link>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
            <div className="card mt-5 align-items-center text-center">
              <FaCogs size="56" color="#3e2a67" />
              <div className="card-body">
                <h5 className="card-title">NOSSOS CLIENTES</h5>
                <p className="card-text">
                  Processos administrativos e operacionais que são custosos e podem tomar mais tempo
                  que o necessário são os principais sintomas a serem combatidos na transformação
                  digital.
                </p>
                <NavigationButton to="/about" sectionId="clients" />
              </div>
            </div>
          </div>
        </div>
      </div>

      
    </>
  );
}
