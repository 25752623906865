import React from 'react';
import { RiFilePaper2Line, RiEyeLine, RiVipDiamondLine } from 'react-icons/ri';
import Carousel from 'react-multi-carousel';

import 'react-multi-carousel/lib/styles.css';
import './style.scss';

import nbwSquare from '../../assets/img/gaspartecroxo.png';
import logoAbraweb from '../../assets/img/AboutLogos/logoAbraweb.png';
import logoAcate from '../../assets/img/AboutLogos/logoAcate.png';
import logoBlusoft from '../../assets/img/AboutLogos/logoBlusoft.png';
import logoNovaCoop from '../../assets/img/AboutLogos/logoNovaCoop.png';

// Logo dos parceiros 
import industriaBuschle from '../../assets/img/Parceiros/industria/case-buschle-lepper-logo.png';
import industriaHitache from '../../assets/img/Parceiros/industria/Hitachi-ABB-OK.png'
import industriaArtico from '../../assets/img/Parceiros/industria/logo_artico-industria-de-refrigeracao-ltda_W1Dn8v.png'
import industriaNetzsche from '../../assets/img/Parceiros/industria/th_netzsch.png'

import varejoOnlineAzul from '../../assets/img/Parceiros/varejoOnline/logo-azul.png'
import varejoOnlineVillela from '../../assets/img/Parceiros/varejoOnline/logo-grupo-villela.png'
import varejoSegurimax from '../../assets/img/Parceiros/varejoOnline/logo.png'
import varejoOnlineZeus from '../../assets/img/Parceiros/varejoOnline/logo_mobile.png'
import varejoOnlinePosthaus from '../../assets/img/Parceiros/varejoOnline/posthaus-moda-638312488096893399.png'

import varejoTextilHering from '../../assets/img/Parceiros/varejoTextil/Hering1.png'
import varejoTextilBrandili from '../../assets/img/Parceiros/varejoTextil/logo-brandili.png'
import varejoTextilRevitex from '../../assets/img/Parceiros/varejoTextil/revitex-logo.png'
import varejoTextilLuneli from '../../assets/img/Parceiros/varejoTextil/unnamed.png'

export default function About() {
  const carouselResponsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 3,
      slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: 1199, min: 850 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 849, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  function CustomRightArrow() {
    return false;
  }
  function CustomLeftArrow() {
    return false;
  }

  let animationExecuted = false;

  window.addEventListener(
    'scroll',
    () => {
      if (window.location.href.split('/')[3] === 'About') {
        const bannerMission = document.getElementById('bannerMission');
        if (window.scrollY > bannerMission.offsetTop - 800 && !animationExecuted) {
          bannerMission.classList.add('fadeIn');
          document.getElementById('mission').classList.add('slide-left');
          document.getElementById('values').classList.add('slide-right');

          animationExecuted = true;
        }
      }
    },
    false
  );
  return (
    <>
      {/* About */}
      <div className="container about">
        <div className="row align-items-start">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-7 col-xl-7">
            <h2 className="title-3 fadeInLeft">SOBRE NÓS</h2>
            <p className="subtitle fadeInLeft text-start">Gaspartec: QUEM SOMOS?</p>
            <p className="text-justify fadeIn">
              Hub especializado em oferecer serviços e soluções de TI para os mais variados segmentos, com foco em inovação, melhoria contínua e otimização de processos. Trabalhamos como parceiros estratégicos, comprometidos em transformar desafios em oportunidades de crescimento e excelência através de SERVIÇOS ESPECIALIZADOS E SOLUÇÕES DE NICHO.
            </p>
            <strong className="text-highlight fadeIn"> #SomosGASPARTEC #VemSerDigital</strong>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-5 col-xl-5">
            <img className="slide-right" src={nbwSquare} alt="Foto letreito logo NBW" />
          </div>
        </div>
      </div>

      {/* Mission, Vison, Values */}
      <div className="container banner-mission">
        <div className="container">
          <div className="row align-items-start" id="bannerMission">
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4" id="mission">
              <div className="d-flex flex-column align-items-center">
                <RiFilePaper2Line size="56" color="#fff" />
                <strong>Missão</strong>
                <p className="custom-label text-color-light mt-1 text-center">
                  Impulsionar o sucesso de nossos clientes por meio de tecnologias avançadas e práticas eficientes, sempre buscando a melhor alternativa para resolução de problemas.
                </p>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
              <div className="d-flex flex-column align-items-center">
                <RiEyeLine size="56" color="#fff" />
                <strong>Visão</strong>
                <p className="custom-label text-color-light mt-1 text-center">
                  DESDE 2012 IMPULSIONANDO AS EMPRESAS DE GASPAR E REGIÃO,  ATRAVÉS DA TECNOLOGIA E INOVAÇÃO.
                </p>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4" id="values">
              <div className="d-flex flex-column align-items-center">
                <RiVipDiamondLine size="56" color="#fff" />
                <strong>Nosso maior capital</strong>
                <p className="custom-label text-color-light mt-1 text-center">
                  Nossos clientes!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Carousel partners */}
      <div className="container carousel-container">
        <div className="row align-items-start">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-7 col-xl-7">
            <h2 className="title-3 fadeInLeft mb-5">NOSSOS PARCEIROS</h2>
          </div>
          <Carousel
            swipeable={false}
            draggable={false}
            responsive={carouselResponsive}
            ssr
            infinite
            autoPlay
            autoPlaySpeed={1500}
            keyBoardControl
            customTransition="all .5"
            transitionDuration={500}
            customLeftArrow={<CustomLeftArrow />}
            customRightArrow={<CustomRightArrow />}
            itemClass="text-center"
          >
            <img src={logoAbraweb} height="70px" alt="Logo Abraweb" />
            <img src={logoAcate} height="70px" alt="Logo Acate" />
            <img src={logoBlusoft} height="70px" alt="Logo Blusoft" />
            <img src={logoNovaCoop} height="70px" alt="Logo Nova Cooperativa" />
          </Carousel>
        </div>
      </div>



      {/* Carousel clients industria*/}
      <div className="container-fluid">
        <div className="container">
          <h1 className="title-1 fadeInLeft mb-5 text-center ml-5 textmargin " id="clients">Conheça nossos clientes</h1>
          <div className="row align-items-start">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-7 col-xl-7">
              <h2 className="title-3 fadeInLeft mb-5">INDÚSTRIA</h2>
            </div>
          </div>
        </div>
        <div className="carousel-wrapper">
          <Carousel
            swipeable={false}
            draggable={false}
            responsive={carouselResponsive}
            ssr
            infinite
            autoPlay
            autoPlaySpeed={1500}
            keyBoardControl
            customTransition="all .5"
            transitionDuration={500}
            customLeftArrow={<CustomLeftArrow />}
            customRightArrow={<CustomRightArrow />}
            itemClass="text-center"
          >
            <img src={industriaArtico} height="70px" alt="Logo Artico" />
            <img src={industriaBuschle} height="70px" alt="Logo Buschle" />
            <img src={industriaHitache} height="70px" alt="Logo Hitache" />
            <img src={industriaNetzsche} height="70px" alt="Logo Netzsche" />
          </Carousel>
        </div>
      </div>



      {/* Carousel clients varejo online */}
      <div className="container-fluid">
        <div className="container carousel-container">
          <div className="row align-items-start">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-7 col-xl-7 mt-0">
              <h2 className="title-3 fadeInLeft mb-5">VAREJO ONLINE</h2>
            </div>
          </div>
        </div>
        <div className='carousel-wrapper'>
          <Carousel
            swipeable={false}
            draggable={false}
            responsive={carouselResponsive}
            ssr
            infinite
            autoPlay
            autoPlaySpeed={1500}
            keyBoardControl
            customTransition="all .5"
            transitionDuration={500}
            customLeftArrow={<CustomLeftArrow />}
            customRightArrow={<CustomRightArrow />}
            itemClass="text-center"
          >
            <img src={varejoOnlineAzul} height="70px" alt="Logo Azul" />
            <img src={varejoOnlinePosthaus} height="70px" alt="Logo Posthaus" />
            <img src={varejoOnlineVillela} height="70px" alt="Logo Villela" />
            <img src={varejoOnlineZeus} height="70px" alt="Logo Zeus" />
            <img src={varejoSegurimax} height="70px" alt="Logo Segurimax" />
          </Carousel>
        </div>
      </div>

      {/* Carousel clients industria */}
      <div className="container-fluid">
        <div className="container carousel-container">
          <div className="row align-items-start">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-7 col-xl-7">
              <h2 className="title-3 fadeInLeft mb-5">VAREJO TÊXTIL</h2>
            </div>
          </div>
        </div>
        <div className='carousel-wrapper'>
          <Carousel
            swipeable={false}
            draggable={false}
            responsive={carouselResponsive}
            ssr
            infinite
            autoPlay
            autoPlaySpeed={1500}
            keyBoardControl
            customTransition="all .5"
            transitionDuration={500}
            customLeftArrow={<CustomLeftArrow />}
            customRightArrow={<CustomRightArrow />}
            itemClass="text-center"
          >
            <img src={varejoTextilBrandili} height="70px" alt="Logo Brandili" />
            <img src={varejoTextilHering} height="70px" alt="Logo Hering" />
            <img src={varejoTextilLuneli} height="70px" alt="Logo Luneli" />
            <img src={varejoTextilRevitex} height="70px" alt="Logo Revitex" />
          </Carousel>
        </div>
      </div>

    </>
  );
}
