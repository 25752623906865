/* eslint-disable no-bitwise */
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaLinkedinIn, FaInstagram, FaWhatsapp } from 'react-icons/fa';
import ReactGA from 'react-ga';

import '../../../../custom.scss';
import '../../website.scss';
import './style.scss';

import logo from '../../../../img/instituto_gaspartec_logo-preview.png'

function changeNavBarStyle() {
  setTimeout(() => {
    if (window.location.href.split('/')[3] === '') {
      document.getElementById('navbar').classList.remove('navbar-black');
    } else {
      document.getElementById('navbar').classList.add('navbar-black');
    }

    document.getElementById('hamburger-1').classList.remove('is-active');
    document.getElementById('navbarNavAltMarkup').classList.remove('show');
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname);
  }, 10);
}

export default function Navbar() {
  // Google Analytics
  ReactGA.initialize('UA-200283130-1');

  // Bitrix24 - Formulário Cadastro de Empresa
  (() => {
    const s1 = document.createElement('script');
    const s0 = document.getElementsByTagName('script')[0];
    s1.async = true;
    s1.src = `https://cdn.bitrix24.com.br/b18784749/crm/form/loader_16.js?${
      (Date.now() / 180000) | 0
    }`;
    s1.setAttribute('data-b24-form', 'inline/16/4v4u9o');
    s1.setAttribute('data-skip-moving', 'true');
    s0.parentNode.insertBefore(s1, s0);
  })();

  // Bitrix24 - Botão CRM
  (() => {
    const s1 = document.createElement('script');
    const s0 = document.getElementsByTagName('script')[0];
    s1.async = true;
    s1.src = `https://cdn.bitrix24.com.br/b18784749/crm/site_button/loader_2_0kxdsb.js?${
      (Date.now() / 60000) | 0
    }`;
    s0.parentNode.insertBefore(s1, s0);
  })();

  useEffect(() => {
    if (window.location.href.split('/')[3] === '') {
      document.getElementById('navbar').classList.remove('navbar-black');
    } else {
      document.getElementById('navbar').classList.add('navbar-black');
    }
  });

  // Evento botão hamburger
  function toggleHamburger() {
    document.getElementById('hamburger-1').classList.toggle('is-active');
    document.getElementById('navbarNavAltMarkup').classList.toggle('show');
  }

  return (
    <nav id="navbar" className="navbar navbar-expand-lg navbar-dark fixed-top">
      <div className="container-fluid">
        <Link to="/" onClick={() => changeNavBarStyle()} className="navbar-brand">
          <img src={logo} title="Gaspartec" alt="Logo Gaspartec" />
        </Link>
        <div
          className="hamburger"
          id="hamburger-1"
          role="button"
          tabIndex="0"
          onClick={toggleHamburger}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              toggleHamburger()
            }
          }}
        >
          <span className="line" />
          <span className="line" />
          <span className="line" />
        </div>
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div className="navbar-nav">
            <Link to="/" onClick={changeNavBarStyle} className="nav-link">
              Início
            </Link>
            <Link to="/About" onClick={changeNavBarStyle} className="nav-link">
              Sobre
            </Link>
            <Link to="/Services" onClick={changeNavBarStyle} className="nav-link">
            Nossas Marcas
            </Link>
            <Link to="/Contact" onClick={changeNavBarStyle} className="nav-link">
              Contato
            </Link>
            {/* <Link to="/Carreers" onClick={changeNavBarStyle} className="nav-link py-0 px-0">
              <button type="button" className="btn btn-primary btn-color-pg" >
              Acesso Suporte 
              </button>
            </Link> */}
          </div>

          <div className="navbar-nav nav-icons">
            
            <div className="social-circle">
              <a
                className="linkedin-icon"
                href="https://www.linkedin.com/company/instituto-gaspartec/"
                target="_blank"
                rel="noreferrer"
                title="Linkedin"
              >
                <FaLinkedinIn size="20" />
              </a>
            </div>
            <div className="social-circle">
              <a
                className="instagram-icon"
                href="https://www.instagram.com/instituto.gaspartec/"
                target="_blank"
                rel="noreferrer"
                title="Instagram"
              >
                <FaInstagram size="20" />
              </a>
            </div>
            <div className="social-circle">
              <a
                className="whatsapp-icon"
                href="https://api.whatsapp.com/send?phone=5547999200424"
                target="_blank"
                rel="noreferrer"
                title="Whatsapp"
              >
                <FaWhatsapp size="20" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
